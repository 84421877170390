import React from "react";
import logo from "./logo.svg";
import "./App.css";
import RoutesPage from "./routes";

function App() {
  return (
    <RoutesPage />
  );
}

export default App;
